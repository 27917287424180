import { defineAsyncComponent } from 'vue';
import { mountApp, app } from '@/js/app-entries/app-entry-points/global';

const MainHeader = defineAsyncComponent(() => import('@/js/global/header/MainHeader.vue'));
const LogoSlider = defineAsyncComponent(() => import('@/js/components/slider/LogoSlider.vue'));

app.component('MainHeader', MainHeader);
app.component('LogoSlider', LogoSlider);

mountApp();
